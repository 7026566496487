import React from "react";
import { graphql } from "gatsby";
import { Headline1Sans } from "../system42";
import LayoutExamples from "../layouts/LayoutExamples";
import * as styles from "./styles/examples.module.css";
import { ExampleList } from "../components/ExampleList";

const Examples = ({ data }) => {
  const examples = data.allStrapiExample.edges.map((edge) => edge.node);

  return (
    <LayoutExamples title={"Template Library"} activeMenuItem={"templates"}>
      <Headline1Sans customTag={"h2"} className={styles.title}>
        User testing
        <br />
        templates & examples.
      </Headline1Sans>
      <p className={styles.intro}>
        Knowing what and how to test can be challenging. Dive into our user
        testing templates for inspiration from different industries.{" "}
        {/*and turn them into templates in just a few clicks.*/}
      </p>

      <ExampleList context={"library"} examples={examples} />
    </LayoutExamples>
  );
};

export const query = graphql`
  query {
    allStrapiExample(filter: { online: { eq: true } }) {
      edges {
        node {
          id
          url
          title
          type
          device
          companyName
          cardTitleWhite
          taskTypes {
            title
            url
          }
          categories {
            title
            url
          }
          cardColor
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default Examples;
